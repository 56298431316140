import React, { Component } from 'react';
import { ReactComponent as IconEnstudio } from './../../assets/img/icon-enstudio.svg';

class LoadingPage extends Component{
  constructor(){
    super();
    
    this.state = {
      // signIn: ''
    }

    // this.cancellableLogout = '';

    // this.navToggle = this.navToggle.bind(this);
  }

  componentDidMount(){}

  componentDidUpdate(prevProps, prevState){}

  componentWillUnmount(){}

  render(){
    return (
      <div className={`loading-page ${this.props.forContent ? 'for-content' : ''} ${this.props.forSec ? 'for-section' : ''}`}>
        <IconEnstudio />
        {this.props.text ? (<span>{this.props.text}</span>) : ''}
      </div>
    );
  }
}

export default LoadingPage;